<script lang="ts" setup>

</script>

<template>
  <UIcon name="i-svg-spinners-ring-resize" class="size-5 text-gray-400" :class="$attrs.class" />
</template>

<style scoped>

</style>
